import * as React from 'react';

import { Layout } from 'src/components/Layout';
import { SEO } from 'src/components/SEO';
import { PageTitle } from 'src/components/PageTitle';
import { TeamCard } from 'src/components/TeamCard';
import { teamMembers } from 'src/content/teamMembers';

export interface TeamMember {
  name: string;
  title: string;
  email: string;
  link: string;
  phone: string;
  image: string;
  gif?: string;
}

const FamilyPage = () => (
  <Layout>
    <SEO title="Alviso Family" />
    <div className="container">
      <PageTitle title="Alviso family" />
      <div className="row justify-content-between">
        {
          teamMembers.map((item: TeamMember, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <TeamCard
                name={item.name}
                title={item.title}
                email={item.email}
                phone={item.phone}
                link={item.link}
                image={item.image}
                gif={item.gif}
              />
            </div>
          ))
        }
      </div>
    </div>
  </Layout>
);

export default FamilyPage;
