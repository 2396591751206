import noPhoto from 'src/assets/images/family/no_photo.png';
import zuzka from 'src/assets/images/family/zuzka_potuckova.png';
import zuzkaGif from 'src/assets/images/family/zuzka_potuckova.gif';
import klaraH from 'src/assets/images/family/klara_hanackova.png';
import klaraHGif from 'src/assets/images/family/klara_hanackova.gif';
import klaraO from 'src/assets/images/family/klara_ondrackova.png';
import klaraOGif from 'src/assets/images/family/klara_ondrackova.gif';
import peca from 'src/assets/images/family/peca_zatloukal.png';
import pavel from 'src/assets/images/family/pavel_kubal.png';
import vojta from 'src/assets/images/family/vojta_nemec.png';
import misa from 'src/assets/images/family/misa_rysava.png';
import misaH from 'src/assets/images/family/misa_hauserova.png';
import davca from 'src/assets/images/family/david_janda.png';
import david from 'src/assets/images/family/david_pavliska.png';
import honza from 'src/assets/images/family/jan_galuska.png';
import marek from 'src/assets/images/family/marek_janda.png';
import barca from 'src/assets/images/family/barca_janu.png';
import peta from 'src/assets/images/family/peta_kollarikova.png';
import adriana from 'src/assets/images/family/adriana_macinic.png';
import marie from 'src/assets/images/family/marie_puczokova.png';
import klaraS from 'src/assets/images/family/klara_sebakova.png';
import terka from 'src/assets/images/family/terka_vlachova.png';
import matej from 'src/assets/images/family/matej_belina.png';
import ivana from 'src/assets/images/family/ivana_tomasevska.png';
import lucie from 'src/assets/images/family/lucie_hustakova.png';
import zuzkaV from 'src/assets/images/family/zuzka_vojtechova.png';
import petaC from 'src/assets/images/family/petra_castova.png';
import jarda from 'src/assets/images/family/jaromir_fulnek.png';
import ondra from 'src/assets/images/family/ondrej_jiricek.png';
import hanka from 'src/assets/images/family/hanka_kopecka.png';
import misaP from 'src/assets/images/family/michaela_pucikova.png';
import kata from 'src/assets/images/family/kata_skalakova.png';
import daniel from 'src/assets/images/family/daniel_jarzabek.png';
import viktorie from 'src/assets/images/family/viktorie_slachtova.png';
import ondraV from 'src/assets/images/family/ondrej_varecha.png';
import michalJ from 'src/assets/images/family/michal_janda.png';
import lucieS from 'src/assets/images/family/lucie_svorcova.png';
import stana from 'src/assets/images/family/stana_mudrinic.png';
import adam from 'src/assets/images/family/adam_ciz.png';
import lenka from 'src/assets/images/family/lenka_nemcova.png';

export const teamMembers = [
  {
    'name': 'Peca',
    'title': 'The big boss',
    'email': 'petr.zatloukal@alviso.cz',
    'link': 'https://www.linkedin.com/in/petrzatloukal/',
    'phone': '',
    'image': peca,
  },
  {
    'name': 'Pavel',
    'title': 'The big boss',
    'email': 'pavel.kubal@alviso.cz',
    'link': 'https://www.linkedin.com/in/pavelkubal/',
    'phone':  '',
    'image': pavel,
  },
  {

    'name': 'Vojta',
    'title': 'The big boss',
    'email': 'vojtech.nemec@alviso.cz',
    'link': 'https://www.linkedin.com/in/vojtanemec/',
    'phone':  '',
    'image': vojta,
  },
  {
    'name': 'Jaromír',
    'title': 'The finance guru',
    'email': 'jaromir.fulnek@alviso.cz',
    'link': 'https://www.linkedin.com/in/jaromirfulnek/',
    'phone':  '',
    'image': jarda,
  },
  {
    'name': 'Míša',
    'title': 'The sales queen',
    'email': 'michaela.hulakova@alviso.cz',
    'link': 'https://www.linkedin.com/in/michaela-hul%C3%A1kov%C3%A1-ry%C5%A1av%C3%A1-547120b/',
    'phone':  '+420 739 534 701',
    'image': misa,
  },
  {
    'name': 'Matěj',
    'title': 'The recruiter king',
    'email': 'matej.belina@alviso.cz',
    'link': 'https://www.linkedin.com/in/matejbelina/',
    'phone':  '',
    'image': matej,
  },
  {
    'name': 'Honza',
    'title': 'The recruiter king',
    'email': 'jan.galuska@alviso.cz',
    'link': 'https://www.linkedin.com/in/jangaluska/',
    'phone':  '',
    'image': honza,
  },
  {
    'name': 'Péťa',
    'title': 'The recruiter goddess',
    'email': 'petra.castova@alviso.cz',
    'link': 'https://www.linkedin.com/in/adam-%C4%8D%C3%AD%C5%BE-761574149/',
    'phone':  '',
    'image': petaC,
  },
  {
    'name': 'Adam',
    'title': 'The recruiter god',
    'email': 'adam.ciz@alviso.cz',
    'link': '',
    'phone':  '',
    'image': adam,
  },
  {
    'name': 'Klára H.',
    'title': 'The recruiter goddess',
    'email': 'klara.hanackova@alviso.cz',
    'link': 'https://www.linkedin.com/in/klára-hanáčková-824263a2/',
    'phone':  '',
    'image': klaraH,
    'gif': klaraHGif,
  },
  {
    'name': 'Míša',
    'title': 'The recruiter goddess',
    'email': 'michaela.hauserova@alviso.cz',
    'link': 'https://www.linkedin.com/in/michaela-hauserová-23808a133/',
    'phone':  '',
    'image': misaH,
  },
  {
    'name': 'Lucie H.',
    'title': 'The recruiter goddess',
    'email': 'lucie.hustakova@alviso.cz',
    'link': 'https://www.linkedin.com/in/luciehustakova/',
    'phone':  '',
    'image': lucie,
  },
  {
    'name': 'David J.',
    'title': 'The recruiter king',
    'email': 'david.janda@alviso.cz',
    'link': 'https://www.linkedin.com/in/jandadavid/',
    'phone':  '',
    'image': davca,
  },
  {
    'name': 'Marek',
    'title': 'The recruiter king',
    'email': 'marek.janda@alviso.cz',
    'link': 'https://www.linkedin.com/in/marek-janda-151b37207/',
    'phone':  '',
    'image': marek,
  },
  {
    'name': 'Michal',
    'title': 'The recruiter king',
    'email': 'michal.janda@alviso.cz',
    'link': 'https://www.linkedin.com/in/jandamichal007/',
    'phone':  '',
    'image': michalJ,
  },
  {
    'name': 'Barča',
    'title': 'The recruiter goddess',
    'email': 'barbora.janu@alviso.cz',
    'link': 'https://www.linkedin.com/in/barujanu/',
    'phone':  '',
    'image': barca,
  },
  {
    'name': 'Dan',
    'title': 'The recruiter king',
    'email': 'daniel.jarzabek@alviso.cz',
    'link': 'https://www.linkedin.com/in/danieljarzabek1/',
    'phone':  '',
    'image': daniel,
  },
  {
    'name': 'Ondra',
    'title': 'The recruiter king',
    'email': 'ondrej.jiricek@alviso.cz',
    'link': 'https://www.linkedin.com/in/ondřej-jiř%C3%ADček-2a2573143/',
    'phone':  '',
    'image': ondra,
  },
  {
    'name': 'Péťa',
    'title': 'The recruiter goddess',
    'email': 'petra.kollarikova@alviso.cz',
    'link': 'https://www.linkedin.com/in/petra-kolláriková-14027b12b/',
    'phone':  '',
    'image': peta,
  },
  {
    'name': 'Hanka',
    'title': 'The recruiter goddess',
    'email': 'hana.kopecka@alviso.cz',
    'link': 'https://www.linkedin.com/in/hanarumiskova/',
    'phone':  '',
    'image': hanka,
  },
  {
    'name': 'Adriana',
    'title': 'The recruiter goddess',
    'email': 'adriana.macinic@alviso.cz',
    'link': 'https://www.linkedin.com/in/adriana-macinic-9434a2129/',
    'phone':  '',
    'image': adriana,
  },
  {
    'name': 'Stana',
    'title': 'The recruiter goddess',
    'email': 'stana.mudrinic@alviso.cz',
    'link': 'https://www.linkedin.com/in/stana-pfumvuti-mudrini%C4%87-b34339179/',
    'phone':  '',
    'image': stana,
  },
  {
    'name': 'Lenka',
    'title': 'The recruiter goddess',
    'email': 'lenka.nemcova@alviso.cz',
    'link': 'https://www.linkedin.com/in/lenkanemcova/',
    'phone':  '',
    'image': lenka,
  },
  {
    'name': 'Klára O.',
    'title': 'The recruiter goddess',
    'email': 'klara.ondrackova@alviso.cz',
    'link': 'https://www.linkedin.com/in/klaraondr/',
    'phone':  '',
    'image': klaraO,
    'gif': klaraOGif,
  },
  {
    'name': 'Marie',
    'title': 'The recruiter goddess',
    'email': 'marie.puczokova@alviso.cz',
    'link': 'https://www.linkedin.com/in/marie-puczok-095b241b9/',
    'phone':  '',
    'image': marie,
  },
  {
    'name': 'Míša P.',
    'title': 'The recruiter goddess',
    'email': 'michaela.pucikova@alviso.cz',
    'link': 'https://www.linkedin.com/in/michaelapucikova/',
    'phone':  '',
    'image': misaP,
  },
  {
    'name': 'Klára Š.',
    'title': 'The recruiter goddess',
    'email': 'klara.sebakova@alviso.cz',
    'link': 'https://www.linkedin.com/in/kebabkova/',
    'phone':  '',
    'image': klaraS,
  },
  {
    'name': 'Káťa',
    'title': 'The recruiter goddess',
    'email': 'katerina.skalakova@alviso.cz',
    'link': 'https://www.linkedin.com/in/kateřina-skaláková-00351b192/',
    'phone':  '',
    'image': kata,
  },
  {
    'name': 'Viki',
    'title': 'The recruiter goddess',
    'email': 'viktorie.slachtova@alviso.cz',
    'link': 'https://www.linkedin.com/in/viktorieslachtova/',
    'phone':  '',
    'image': viktorie,
  },
  {
    'name': 'Lucie Š.',
    'title': 'The recruiter goddess',
    'email': 'lucie.svorcova@alviso.cz',
    'link': 'https://www.linkedin.com/in/lucie-%C5%A1vorcov%C3%A1-dis-270606220/',
    'phone':  '',
    'image': lucieS,
  },
  {
    'name': 'Ivana',
    'title': 'The recruiter goddess',
    'email': 'ivana.tomasevska@alviso.cz',
    'link': 'https://www.linkedin.com/in/ivana-markovi%C4%87-toma%C5%A1evska-29289b21b/',
    'phone':  '',
    'image': ivana,
  },
  {
    'name': 'Ondřej V.',
    'title': 'The recruiter god',
    'email': 'ondrej.varecha@alviso.cz',
    'link': 'https://cz.linkedin.com/in/ond%C5%99ej-va%C5%99echa-067b29217',
    'phone':  '',
    'image': ondraV,
  },
  {
    'name': 'Zuzka V.',
    'title': 'The recruiter goddess',
    'email': 'zuzana.vojtechova@alviso.cz',
    'link': 'https://www.linkedin.com/in/zuzana-vojtechova-8b2208161/',
    'phone':  '',
    'image': zuzkaV,
  },
  {
    'name': 'Terka',
    'title': 'The recruiter goddess',
    'email': 'tereza.vlachova@alviso.cz',
    'link': 'https://www.linkedin.com/in/tereza-vlachová-87228a104/',
    'phone':  '',
    'image': terka,
  },
];
