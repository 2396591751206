import * as React from 'react';
import SVG from 'react-inlinesvg';

import { TeamMember } from 'src/pages/family';
import './TeamCard.scss';

import mail from 'src/assets/icons/mail.svg';
import linkedin from 'src/assets/icons/linkedin-white.svg';
import telephone from 'src/assets/icons/telephone.svg';
import teamCardBg from 'src/assets/images/family/team-card-bg.png';

export const TeamCard = ({
  name,
  title,
  email,
  phone,
  link,
  image,
  gif,
}: TeamMember) => (
  <article
    className={[
      'team-card',
      gif ? '' : 'no-gif',
    ].join(' ')}
  >
    <figure className="team-card__background">
      <img src={teamCardBg} alt="Background" />
    </figure>
    <figure className="team-card__image">
      <img src={image} alt={name} />
    </figure>
    {
      gif &&
        <figure className="team-card__animation">
          <img src={gif} alt={name} />
        </figure>
    }
    <div className="d-flex flex-column">
      <div className="team-card__name">
        <SVG src={linkedin} />
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <b>{name}</b>
        </a>
      </div>
      <div className="team-card__email">
        <SVG src={mail} />
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      {
        phone.length > 0 &&
        <div className="team-card__phone">
          <SVG src={telephone} />
          <a href={`to:${phone}`}>{phone}</a>
        </div>
      }
    </div>
  </article>
);
